.login-page {

  .card-login {
    .logo-container {
      width: 120px !important;
      margin-bottom: 50px !important;}
  
    .card-login-body {
      padding: 0px;
    
      .input-group {
        .input-group-append{
          margin-left: 0;
          
        }
      }
    }

    .btn.btn-link.forgot-link {
      color: white;
      font-weight: 600;
      text-transform: none;
      padding: 0px;
      margin: 0px;}
      
    .card-footer {

      div {
        display: block;}

      .alert button.close {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -13px;
        width: 25px;
        height: 25px;
        padding: 3px;}
  
      .alert span[data-notify="icon"] {
        font-size: 22px;
        display: block;
        left: 19px;
        position: absolute;
        top: 50%;
        margin-top: -11px;}
  
      .alert span[data-notify="message"] {
          text-align: left;
          display: block;
          max-width: 89%;
          margin-left: 30px;}
    }
  }
}

.btn-show-password {
  background-color: transparent;
  color: inherit;
  border: 0px;
  border-color: transparent;
  text-align: center;
  padding: 0px 12px;
  font-size: .8rem;
  text-decoration: none;
}

// .login-container {
//   &.container {
//     max-width: 100%;
//   }

//   .card-login {
//     box-shadow: 0 0px 50px #061442;
//   	border-radius: 20px;
//   	background-color: #25365e;
//   }

//   .login-error {
//     color: #ff5213;
//     margin-bottom: 5px;
//   }

//   .login-form {
//     .input-group, .login-logo {
//       margin-bottom: 20px;
//     }

//     .input-group-text,
//      input:-webkit-autofill,
//      input.form-control {
//       background-color: #fff !important;
//     }

//     .form-control {
//       width: 250px;
//     }

//     .login-button {
//       background-color: #dbb305;
//       font-weight: bold;
//     }
//   }

//   .login-link {
//     text-align: left;

//     a {
//       color: #fff !important;
//     }

//     .alert button.close {
//       position: absolute;
//       right: 10px;
//       top: 50%;
//       margin-top: -13px;
//       width: 25px;
//       height: 25px;
//       padding: 3px;}

//     .alert span[data-notify="icon"] {
//       font-size: 22px;
//       display: block;
//       left: 19px;
//       position: absolute;
//       top: 50%;
//       margin-top: -11px;}

//     .alert span[data-notify="message"] {
//         display: block;
//         max-width: 89%;
//         margin-left: 30px;}
//   }
// }

// .navbar {
//   box-shadow: none !important;

//   .navbar-brand {
//     background-color: #fff;
//     padding: 1rem;
//     border-radius: 50px;
//     margin-top: 1rem;
//   }
// }
