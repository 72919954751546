.search-container {
  margin-top: 160px;

  .no-result-icon {
    font-size: 60px;
    color: #aaa;
  }
  .search-text input {
    font-size: 1.2em;
  }

  .total-hits {
  }

  .search-result {
    margin-top: 0px;
  }

  ul.search-aggregate {
    li {
      a {
        color: #040404 !important;
        cursor: pointer;
        word-break: break-word;
        text-align: left;
      }
      span {
        cursor: pointer;
        margin: auto 0;
      }

      &.list-group-item {
        color: #6db0e8;
        border: none;
        padding: 0.7rem;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        font-size: 1.1em;

        .badge {
          color: #aaa;
          border: none;
          font-size: 0.9em;
        }
      }

      &.agg-type {
        text-transform: uppercase;
        border-bottom: 1px solid #6db0e8;
        padding-bottom: 0.3rem;
      }
    }
  }

  .search-form-container {
    margin-bottom: 25px;
  }
  .arrows-1_refresh-69 {
    font-size: 11px;
    font-weight: 800;
  }

  .clear-filters {
    align-self: flex-end;
    font-size: 0.9em;
  }

  .search-pagination {
    .page-item.active a {
      background-color: #ccc;
      box-shadow: none;
    }
  }

  .aggregate-container {
    display: flex;
    flex-direction: column;
  }
}
