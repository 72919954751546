@import '../../now-ui-assets/assets/scss/now-ui-kit/_variables.scss';

.modal {
  .modal-dialog {
    max-width: 450px;

    .modal-content {
        background-color: $brand-primary !important;
        color: #FFFFFF;}
  }

  .card {
    margin-bottom: 0px;

    .card-header {
      margin-bottom: 30px;}
    
    .card-body {
      padding: 5px;
      
      hr {
        background-color: #ffff !important;}
    }
      
      .card-footer {
        margin-top: 10px;}
        
    .register-form {
      .logo {
        width: 100px;
        margin-bottom: 10px; }


      .input-group-text,
      input:-webkit-autofill,
      input.form-control {
        background-color: #ffff !important; 
        border: none;
      }

      input[readonly] {
          color: #888888;
          opacity: 1;
          filter: alpha(opacity=100); }

      input::placeholder {
        color: #888888;
        opacity: 1;
        filter: alpha(opacity=100); }

      input::-moz-placeholder {
        color: #888888;
        opacity: 1;
        filter: alpha(opacity=100); }

      input:-moz-placeholder {
        color: #888888;
        opacity: 1;
        filter: alpha(opacity=100); }

      input::-webkit-input-placeholder {
        color: #888888;
        opacity: 1;
        filter: alpha(opacity=100); }

      input:-ms-input-placeholder{
        color: #888888;
        opacity: 1;
        filter: alpha(opacity=100); }
    
    }
  }
}

// .reset-password-modal {
//   max-width: 400px;
//   background-color: #25365e;
//   color: #FFFFFF;
// }


// .reset-container {
//   &.container {
//     max-width: 100%;
//   }

//   .card-reset {
//     box-shadow: 0 0px 50px #061442;
//   	border-radius: 20px;
//   	background-color: #25365e;
//   }

//   .login-error {
//     color: #ff5213;
//     margin-bottom: 5px;
//   }

//   .reset-form {
//     .input-group, .login-logo {
//       margin-bottom: 20px;
//     }

//     .input-group-text,
//      input:-webkit-autofill,
//      input.form-control {
//       background-color: #ffff !important;
//     }

//     .form-control {
//       width: 250px;
//     }

//     .reset-button {
//       background-color: #dbb305;
//       font-weight: bold im;
//     }
//   }

//   .reset-link {
//     text-align: left;

//     a {
//       color: #fff !important;
//     }
//   }
// }
// .title {
//   margin-bottom: 20px;
//   font-size: 24px;
//   color: #fff
// }
// .navbar {
//   box-shadow: none !important;

//   .navbar-brand {
//     background-color: #fff;
//     padding: 1rem;
//     border-radius: 50px;
//     margin-top: 1rem;
//   }
// }

// ul {
//   list-style-type: circle;
//   margin: 0px 0px 10px 10px;
//   padding: 0px 0px 10px 10px;
// }