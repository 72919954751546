@import '../../now-ui-assets/assets/scss/now-ui-kit/variables';

.solomon-header {
  background-color: #025298;
  padding: 0.5rem 2rem !important;

  .solomon-brand {
    margin: 0px;
    width: 80px;
  }

  .masthead-nav {
    margin-left:auto;
  }

  .username {
    margin: auto;
  }

  .header-badge {
    text-align: center;
    height: 36px;
    width: 36px;
    margin: auto;
    color: #025298 !important;
    padding: 4px;
    line-height: unset;
    border-radius: 18px;
    font-size: 1.15rem;
  }

  .solomon-dropdown {
    margin: 7px 1px;
    padding: 0px;

    .row {
      display: contents;
    }

    .dropdown-item {
      font-family: inherit;
      margin: 0px;
      font-size: 1em;
      text-align: center;
    }
  }
}
