.home-container {
  text-align: center;

  .search-text {
    input {
      width: 500px !important;
    }

    button {
      margin: 0;
      color: #dbb305;
      border-left: 0px !important;
      border-radius: 1.3rem;
    }

    input, button {
      border: 1px solid #dbb305;
      outline: none;
    }
  }

  .cover {
    margin: auto;
  }

  .solomon-logo {
    width: 150px;
    margin-bottom: 1rem;
  }
}
