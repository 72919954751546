.result-card {
  margin-bottom: 1em;

  .title {
    font-weight: bold;
    color: #025298;

    h3 {
      margin-bottom: 0px;
    }
  }
  .author {
    color: #333;
    text-transform: uppercase;
  }

  .summary {
    margin-bottom: 0px;
  }

  .subjects {
    color: #444;
    text-transform: capitalize;
    // text-decoration: underline;

    .badge {
      // margin-left: 2px;
      // margin-right: 2px;
    }
  }
}
