#results {
  text-align: left;
  // min-height: 800px;
  position: relative;
}

#results .loading-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
}
